<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6 lg:col-5">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar
                      size="large"
                      class="primary"
                      icon="pi pi-user-plus"
                    />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Add New User
                    </div>
                    <div class="text-sm text-500">Create new user account</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="md:col-8 sm:col-12 comp-grid">
            <div class="card nice-shadow-6">
              <div>
                <form
                  ref="observer"
                  tag="form"
                  @submit.prevent="startSaveRecord()"
                  @reset="resetForm"
                >
                  <div class="grid">
                    <div class="col-12">
                      <div class="mb-2 font-bold text-sm">Name *</div>
                      <div id="ctrl-name-holder">
                        <InputText
                          ref="ctrlname"
                          v-model.trim="formData.name"
                          label="Name"
                          type="text"
                          placeholder=" "
                          class="w-full"
                          :class="getErrorClass('name')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Username *</div>
                      <div id="ctrl-username-holder">
                        <check-duplicate
                          v-model="formData.username"
                          check-path="components_data/users_username_exist/"
                          v-slot="checker"
                        >
                          <InputText
                            ref="ctrlusername"
                            @blur="checker.check"
                            :loading="checker.loading"
                            v-model.trim="formData.username"
                            label="Username"
                            type="text"
                            placeholder=" "
                            class="w-full"
                            :class="getErrorClass('username')"
                          >
                          </InputText>
                          <small
                            v-if="!checker.loading && checker.exist"
                            class="p-error"
                          >
                            Not available</small
                          >
                          <small v-if="checker.loading" class="text-500"
                            >Checking...</small
                          >
                        </check-duplicate>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Email *</div>
                      <div id="ctrl-email-holder">
                        <check-duplicate
                          v-model="formData.email"
                          check-path="components_data/users_email_exist/"
                          v-slot="checker"
                        >
                          <InputText
                            ref="ctrlemail"
                            @blur="checker.check"
                            :loading="checker.loading"
                            v-model.trim="formData.email"
                            label="Email"
                            type="email"
                            placeholder=" "
                            class="w-full"
                            :class="getErrorClass('email')"
                          >
                          </InputText>
                          <small
                            v-if="!checker.loading && checker.exist"
                            class="p-error"
                          >
                            Not available</small
                          >
                          <small v-if="checker.loading" class="text-500"
                            >Checking...</small
                          >
                        </check-duplicate>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Password *</div>
                      <div id="ctrl-password-holder">
                        <Password
                          ref="ctrlpassword"
                          v-model="formData.password"
                          label="Password"
                          placeholder=" "
                          class="w-full"
                          inputClass="w-full"
                          toggleMask
                          :feedback="true"
                          :class="getErrorClass('password')"
                        />
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">
                        Confirm Password *
                      </div>
                      <div id="ctrl-password-holder">
                        <Password
                          class="w-full"
                          :class="getErrorClass('password')"
                          inputClass="w-full"
                          :feedback="false"
                          toggleMask
                          ref="ctrlconfirmpassword"
                          v-model="formData.confirm_password"
                          label="Confirm Password"
                          placeholder=" "
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-2 font-bold text-sm">Photo</div>
                      <div id="ctrl-photo-holder">
                        <div class="mb-3">
                          <Uploader
                            :class="getErrorClass('photo')"
                            upload-path="s3uploader/upload/photo"
                            v-model="formData.photo"
                            :fileLimit="1"
                            :maxFileSize="3000000"
                            accept=".jpg,.png,.gif,.jpeg"
                            :multiple="false"
                            label="Choose files or drop files here"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="showSubmitButton" class="text-center my-3">
                    <Button
                      class="p-button-primary"
                      type="submit"
                      label="Submit"
                      icon="pi pi-send"
                      :loading="saving"
                    />
                  </div>
                </form>
                <slot :submit="submit" :saving="saving"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
//[--PAGE-IMPORT-STATEMENT--]
import { PageMixin } from "../../mixins/page.js";
import { AddPageMixin } from "../../mixins/addpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "addUsersPage",
  components: {
    //[--PAGE-COMPONENT-NAME--]
  },
  mixins: [PageMixin, AddPageMixin],
  props: {
    pageName: {
      type: String,
      default: "users",
    },
    routeName: {
      type: String,
      default: "usersadd",
    },
    apiPath: {
      type: String,
      default: "users/add",
    },
  },
  data() {
    return {
      formData: {
        name: "",
        username: "",
        email: "",
        password: "",
        confirm_password: "",
        photo: "",
      },
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Add New Users";
      },
    },
  },
  validations() {
    let formData = {
      name: { required },
      username: { required },
      email: { required, email },
      password: { required },
      confirm_password: { sameAsPassword: sameAs(this.formData.password) },
      photo: {},
    };
    return { formData };
  },
  methods: {
    ...mapActions("users", ["saveRecord"]),
    async startSaveRecord() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Form is invalid", "", "error");
        return;
      }
      this.saving = true;
      let payload = this.normalizedFormData();
      let url = this.apiUrl;
      let data = { url, payload };
      this.saveRecord(data).then(
        (response) => {
          this.record = response.data ?? {};
          this.id = this.record["user_id"] ?? null;
          this.saving = false;
          this.resetForm();
          this.submitted = false;
          this.closeDialogs(); // close page dialog that if opened
          this.flashMsg(this.msgAfterSave);
          this.$emit("submitted", this.record);
          if (this.redirect) this.navigateTo(`/users`);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    resetForm() {
      this.formData = {
        name: "",
        username: "",
        email: "",
        password: "",
        confirm_password: "",
        photo: "",
      };
      this.updateFormData();
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      return {
        "p-invalid": this.v$.formData[field].$invalid && this.submitted,
      };
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
